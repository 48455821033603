

/* .img-fluid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
} */

.row > div > figure{
  height: 300px;
  width: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
}

.gallery_img {
  padding: 0 10px;
   object-fit: cover;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
}

.gallery_img:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.img-thumbnail {
  border: 0px;
  border-radius: 2px;
} 
.gallery_img{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}