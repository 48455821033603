
  .post-heading span{
   
      font-size: calc(0.5rem + 8vmin);
      font-weight: 600;
      font-style: italic;
    
  }
  .main_card_item{
      width: 100%;
  }

  .cards__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
    /* flex-flow: column; */
    /* align-items: center; */
    max-width: 1320px;
    width: 100%;
    margin: 0 auto; 
  }
  
 
  .cards__wrapper {
    position: relative;
    margin: 50px 0px 45px;
  }
   
  .cards__items {
    margin-bottom: 24px;
  }
 
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    transition: all .3s ease-in-out;
  }
  .cards__item:hover{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
 
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 2px 4px rgb(139, 137, 137);
    border-radius: 4px;
    overflow: hidden;
    text-decoration: none;
  }
 
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
   
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #000;
    box-sizing: border-box;
    
  }
  
   
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  
  .cards__item__img:hover {
    transform: scale(1.2);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #949494;
    font-size: 15px;
    
  }
 
 
  .button{
    border: 2px solid transparent;
    width: 40%;
    padding: 15px 22px;
    background: #000;
    border-radius: 5px;
    margin-top: 10px;
  }
   .button a{
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  }
   .button:hover{
  border: 2px solid #1ebba3;
  cursor: pointer;
  color: #1ebba3;
    }
   
    @media only screen and (max-width: 720px) {
      .content__blog__container {
        width: 84%;
      }
      .post-heading>h2{
        font-size: 20px;
      }
      .post-heading span{
   
        font-size: calc(0.5rem + 3vmin);
        font-weight: 600;
        font-style: italic;
      
    }



    }


  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
   