 
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #eeeeee;
    border-radius: 0;
    height: 20px;
  }
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #4fbfa8;
    transition: width 0.6s ease;
   width: 100%;
   /* aria-valuenow:100px;
    aria-valuemin:0;
    aria-valuemax:100px; */
  }

  
  /* @media screen and (min-width:720px) {
    .container h2{
      font-size: 30px;
    }
    .lead {
      font-size: 1rem;
    }
    
  }
    
  @media screen and (min-width:360px) {
    .container h2{
      font-size: 15px;
    }
    .lead {
      font-size: .2rem;
    }
    
  } */