.carousel-item img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: cover;
  height: 100vh;
  width: 100%;
}
.heading-carousel {
  position: absolute;
  width: 100%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heading-carousel h1 {
  font-size: 4rem;
  font-weight: bolder;
  font-family: "monospace", sans-serif;
  color: #dee2e6;
  font-family: "monospace", sans-serif;

  word-spacing: 2px;
  letter-spacing: 5px;
}
.heading-carousel h5 {
  font-size: 1.5rem;
  font-weight: 300;
  color: rgb(217, 196, 196);
  font-family: monospace;
  letter-spacing: 3px;
  word-spacing: 1px;
  color: #000;
}
