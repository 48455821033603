.logo {
  height: 10vh;
  width: 100px;
  cursor: pointer;
}
 
.navbar {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 3px;
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 0 0px;

}

.navbar-nav li {
  padding: 0 15px;
}
.nav-link {
  letter-spacing: 3px;
}
.nav-item .last_link a{
  border: 2px solid #ffffff;
  width: 100px;
  background: #000;
  color: #fff;
  border-radius: 3px;
}

.nav-item .last_link:hover {
  /* border: 2px solid #53ce60;
  cursor: pointer; */
}
.FaWhatsappSquare{
  /* font-size: 20px; */
}

.nav-item .nav-link {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;

  height: 100%;
}

.navbar-dark .navbar-nav .nav-link {
  transition: all 0.3s ease-out;
  color: #fff;
  border-radius: 4px;
  padding-left: 20px;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #1ebba3;
}
/* .whatsapp_icon_list{
  height: 100px;
} */
.whatsapp_icon{
  /* height: 200px; */
}
.whatsapp_icon{
  font-size: 50px;
}