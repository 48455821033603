.text-gray-600 {
  color: #949494;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

/* 
         ICONS
        ------------------------
    */
.icon-outlined {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  border-radius: 50%;
  border: 2px solid #fff;
  transition: all 0.3s;
}

.icon-outlined.icon-lg {
  font-size: 2rem;
}

.icon-outlined.icon-sm {
  width: 55px;
  height: 55px;
  font-size: 1.15rem;
}

.icon-outlined.icon-thin {
  border-width: 1px;
}

.icon-hover:hover {
  transform: scale(1.1);
}
.icon-outlined-primary {
  border-color: #4fbfa8;
  color: #4fbfa8;
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #6e6e6e;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2a2a2a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dadada;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #2a2a2a;
  background-color: #fff;
  border-color: #a7dfd4;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(79, 191, 168, 0.25);
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.col-lg-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.text-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  letter-spacing: 0.1em;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.text-center {
  text-align: center !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.col-lg-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}
.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}

h2,
.h2 {
  font-size: calc(1.305rem + 0.66vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 1.8rem;
  }
}

h4,
.h4 {
  font-size: 1.1rem;
}

   /*----Responsive  */
   /* @media only screen and (max-width: 720px) {
    .content__blog__container {
      width: 84%;
    }
    h2{
      font-size: 20px;
    }
    .post-heading span{
  
      font-size: calc(0.5rem + 3vmin);
      font-weight: 600;
      font-style: italic;
    
  }
  
  } */
