@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Baloo 2', cursive;
  box-sizing: border-box;
  scroll-behavior: smooth;
text-transform: capitalize;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
