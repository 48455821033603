.img_contain {
  width: 100%;
  height: 70vh;
  background-image: url(/src/assets/img/bus.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  background-size: cover;
  object-fit: cover;
}
.text_contain {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.text_contain > h1 {
  color: #1ebba3;

  font-size: 48px;
  padding-bottom: 10px;
}
.text_contain p {
  line-height: 28px;
  margin-bottom: 15px;
  color: #000;
  font-size: larger;
}
